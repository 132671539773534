import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal, Button } from '../../components';
import css from './PaymentSetupModal.module.css';

/**
 * Modal that is shown to users after they complete their profile setup
 * to inform them about setting up payment details.
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen - Controls whether the modal is open or closed
 * @param {Function} props.onClose - Function to call when the modal is closed
 * @param {Function} props.onManageDisableScrolling - Function to manage scroll locking
 * @returns {JSX.Element}
 */
const PaymentSetupModal = props => {
  const { isOpen, onClose, onManageDisableScrolling } = props;

  return (
    <Modal
      id="PaymentSetupModal"
      isOpen={isOpen}
      onClose={onClose}
      useDefaultWrapperStyles={false}
      onManageDisableScrolling={onManageDisableScrolling}
      contentClassName={css.modalContent}
    >
      <div className={css.content}>
        <h1 className={css.heading}>
          <FormattedMessage
            id="PaymentSetupModal.title"
            defaultMessage="Set Up Your Payment Details"
          />
        </h1>
        <p className={css.message}>
          <FormattedMessage
            id="PaymentSetupModal.message"
            defaultMessage="To receive payments from brands, you'll need to set up your payment details. You can connect either PayPal or Stripe (or both) to get paid."
          />
        </p>
        <p className={css.message}>
          <FormattedMessage
            id="PaymentSetupModal.recommendation"
            defaultMessage="We recommend setting this up now so you're ready when brands want to work with you."
          />
        </p>
        <Button className={css.button} onClick={onClose}>
          <FormattedMessage id="PaymentSetupModal.confirmButton" defaultMessage="Got it" />
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentSetupModal;
