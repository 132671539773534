// ================ Action types ================ //

export const PROFILE_COMPLETION_CHECK = 'app/profile/PROFILE_COMPLETION_CHECK';
export const PROFILE_COMPLETION_VERIFIED = 'app/profile/PROFILE_COMPLETION_VERIFIED';
export const PROFILE_SETUP_COMPLETE = 'app/profile/PROFILE_SETUP_COMPLETE';
export const PAYMENT_SETUP_MODAL_SHOWN = 'app/profile/PAYMENT_SETUP_MODAL_SHOWN';
export const MARK_PAYMENT_SETUP_NEEDED = 'app/profile/MARK_PAYMENT_SETUP_NEEDED';

// ================ Reducer ================ //

const initialState = {
  profileComplete: false,
  profileCheckingInProgress: false,
  paymentSetupModalShown: false,
  isNewSignup: false,
  needsPaymentSetup: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_COMPLETION_CHECK:
      return { ...state, profileCheckingInProgress: true };
    case PROFILE_COMPLETION_VERIFIED:
      return {
        ...state,
        profileComplete: payload.profileComplete,
        isNewSignup: payload.isNewSignup,
        profileCheckingInProgress: false,
      };
    case PROFILE_SETUP_COMPLETE:
      return { ...state, profileComplete: true, needsPaymentSetup: true };
    case PAYMENT_SETUP_MODAL_SHOWN:
      return { ...state, paymentSetupModalShown: true, needsPaymentSetup: false };
    case MARK_PAYMENT_SETUP_NEEDED:
      return { ...state, needsPaymentSetup: true };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const profileCompletionCheck = () => ({ type: PROFILE_COMPLETION_CHECK });
export const profileCompletionVerified = (profileComplete, isNewSignup = false) => ({
  type: PROFILE_COMPLETION_VERIFIED,
  payload: { profileComplete, isNewSignup },
});
export const profileSetupComplete = () => ({ type: PROFILE_SETUP_COMPLETE });
export const paymentSetupModalShown = () => ({ type: PAYMENT_SETUP_MODAL_SHOWN });
export const markPaymentSetupNeeded = () => ({ type: MARK_PAYMENT_SETUP_NEEDED });

// ================ Thunks ================ //

export const checkProfileCompletion = user => (dispatch, getState) => {
  dispatch(profileCompletionCheck());

  const isCreatorProfileComplete =
    user?.attributes?.profile?.publicData?.userType === 'creator'
      ? user?.attributes?.profile?.firstName &&
        user?.attributes?.profile?.lastName &&
        user?.attributes?.profile?.publicData?.location
      : true;

  // Check if this is a new signup
  const isNewSignup = localStorage.getItem('newUserSignup') === 'true';

  // We no longer remove the flag here - it will be removed after profile update
  // and successful redirection to the StripePayoutPage

  if (isCreatorProfileComplete && isNewSignup) {
    localStorage.removeItem('newUserSignup');
  }

  dispatch(profileCompletionVerified(isCreatorProfileComplete, isNewSignup));

  return isCreatorProfileComplete;
};

export const completeProfileSetup = () => dispatch => {
  dispatch(profileSetupComplete());
};

export const markPaymentSetupModalShown = () => dispatch => {
  dispatch(paymentSetupModalShown());
};
