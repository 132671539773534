import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { Modal, Button } from '../../components';
import css from './ProfileSetupModal.module.css';

/**
 * Modal that is shown to users who need to complete their profile
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to close the modal
 * @param {Function} props.onManageDisableScrolling - Function to manage disable scrolling
 * @returns {JSX.Element}
 */
const ProfileSetupModal = props => {
  const { isOpen, onClose, onManageDisableScrolling } = props;

  const handleButtonClick = () => {
    onClose();
  };

  return (
    <Modal
      id="ProfileSetupModal"
      isOpen={isOpen}
      onClose={onClose}
      useDefaultWrapperStyles={false}
      onManageDisableScrolling={onManageDisableScrolling}
      contentClassName={css.modalContent}
    >
      <div className={css.content}>
        <div className={css.iconWrapper}>
          <div className={css.icon}></div>
        </div>

        <h1 className={css.heading}>
          <FormattedMessage id="ProfileSetupModal.heading" />
        </h1>

        <p className={css.message}>
          <FormattedMessage
            id="ProfileSetupModal.message"
            values={{
              br: () => <br />,
            }}
          />
        </p>

        <Button onClick={handleButtonClick} className={css.button}>
          <FormattedMessage id="ProfileSetupModal.gotItButton" />
        </Button>
      </div>
    </Modal>
  );
};

export default ProfileSetupModal;
