import React from 'react';
import { Link } from 'react-router-dom';
import { NamedLink } from '../../../components';

import logo from '../../../assets/logo.png';
import instaText from '../../../assets/photos/insta-text.png';
import rightIcon from '../../../assets/photos/prev-arrow.png';
import instaLogo from '../../../assets/photos/Instagram logo.png';
import tiktok from '../../../assets/photos/tiktok_logo.png';
import linkedin from '../../../assets/photos/linkedin_logo.png';
import linkedText from '../../../assets/photos/Linkedin_text.svg';
import tiktokText from '../../../assets/photos/tiktok_text.svg';
import mailIcon from '../../../assets/photos/mail.svg';

import css from './FooterNew.module.css';

const FooterNew = () => {
  return (
    <div className={css.root}>
      <div className={css.footer}>
        <div className={css.left}>
          <Link to="/" className={css.ftrBrandLink}>
            <img src={logo} alt="logo" className={css.logo} />
          </Link>
          <p className={css.text}>A marketplace for creators and brands</p>
        </div>
        <div className={css.footerMidCont}>
          <div className={css.footerTitleWrap}>
            <h2 className={css.footerTitle}>Quick links</h2>
          </div>
          <div className={css.footerNavLinksWrap}>
            <Link className={css.footerNavLink} to="/">
              Home
            </Link>
            <Link className={css.footerNavLink} to="/s">
              Creators
            </Link>
            <Link className={css.footerNavLink} to="/p/pricing">
              Pricing
            </Link>
            <Link className={css.footerNavLink} to="/p/resources">
              Resources
            </Link>
            <Link className={css.footerNavLink} to="/p/rewards">
              Creator Program
            </Link>
            <Link className={css.footerNavLink} to="/p/about-us-page">
              About Us
            </Link>
          </div>
        </div>
        <div className={css.right}>
          <div className={css.footerTitleWrap}>
            <h2 className={css.footerTitle}>Contact us</h2>
          </div>
          <div className={css.contact}>
            <div className={css.contactItem}>
              <img src={mailIcon} alt="phone-icon" />
              <span>
                <a href="mailto:info@ukreate.com" className={css.link}>
                  info@ukreate.com
                </a>
              </span>
            </div>
          </div>
          <div className={css.instaWrap}>
            <div className={css.instaIcon}>
              <img src={tiktok} alt="tiktok-icon" />
            </div>
            <a
              href="https://www.tiktok.com/@ukreate"
              target="_blank"
              className={css.instaContIconLink}
              rel="noreferrer"
            >
              <div className={css.instaContWrap}>
                <div className={css.instaCont}>
                  <p className={css.followText}>Follow us on</p>
                  <img src={tiktokText} alt="insta-text" />
                </div>
                <img src={rightIcon} alt="right-icon" />
              </div>
            </a>
          </div>
          <div className={css.instaWrap}>
            <div className={css.instaIcon}>
              <img src={linkedin} alt="linkedin-icon" />
            </div>
            <a
              href="https://www.linkedin.com/company/ukreate-llc"
              target="_blank"
              className={css.instaContIconLink}
              rel="noreferrer"
            >
              <div className={css.instaContWrap}>
                <div className={css.instaCont}>
                  <p className={css.followText}>Follow us on</p>
                  <img src={linkedText} alt="insta-text" />
                </div>
                <img src={rightIcon} alt="right-icon" />
              </div>
            </a>
          </div>
          <div className={css.instaWrap}>
            <div className={css.instaIcon}>
              <img src={instaLogo} alt="insta-icon" />
            </div>
            <a
              href="https://www.instagram.com/ukreate.inc/"
              target="_blank"
              className={css.instaContIconLink}
              rel="noreferrer"
            >
              <div className={css.instaContWrap}>
                <div className={css.instaCont}>
                  <p className={css.followText}>Follow us on</p>
                  <img src={instaText} alt="insta-text" />
                </div>
                <img src={rightIcon} alt="right-icon" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={css.footerBottom}>
        <div className={css.footerCopyRight}>
          <p className={css.copyRight}>© Copyright 2024</p>
          <div className={css.copyRight}>
            <NamedLink name="TermsOfServicePage" className={css.linkText}>
              Terms of Service
            </NamedLink>
            <svg
              className={css.svgIcon}
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.6595 4C1.6595 3.13821 1.94176 2.42277 2.50627 1.85366C3.0708 1.28455 3.78853 1 4.6595 1C5.56273 1 6.28854 1.28455 6.83692 1.85366C7.3853 2.42277 7.6595 3.13821 7.6595 4C7.6595 4.84552 7.3853 5.56098 6.83692 6.14634C6.28854 6.71545 5.56273 7 4.6595 7C3.78853 7 3.0708 6.71545 2.50627 6.14634C1.94176 5.56098 1.6595 4.84552 1.6595 4Z"
                fill="#191A1F"
                fillOpacity="0.5"
              />
            </svg>

            <NamedLink name="PrivacyPolicyPage" className={css.linkText}>
              Privacy Policy
            </NamedLink>
            <svg
              className={css.svgIcon}
              width="9"
              height="8"
              viewBox="0 0 9 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.6595 4C1.6595 3.13821 1.94176 2.42277 2.50627 1.85366C3.0708 1.28455 3.78853 1 4.6595 1C5.56273 1 6.28854 1.28455 6.83692 1.85366C7.3853 2.42277 7.6595 3.13821 7.6595 4C7.6595 4.84552 7.3853 5.56098 6.83692 6.14634C6.28854 6.71545 5.56273 7 4.6595 7C3.78853 7 3.0708 6.71545 2.50627 6.14634C1.94176 5.56098 1.6595 4.84552 1.6595 4Z"
                fill="#191A1F"
                fillOpacity="0.5"
              />
            </svg>

            <NamedLink
              name="CMSPage"
              params={{ pageId: 'acceptable-use-policy' }}
              className={css.linkText}
            >
              Acceptable Use Policy
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
